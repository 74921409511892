//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import EmptyState from '@/components/shared/EmptyState.vue'
export default {
    name: 'AnnualReport',
    components: { EmptyState },
    data: function () {
        return {
            isCheck: false,
            isLoading: false,
            lang: localStorage.lang,
            total_pages: 0,
            total_rows: 0,
            modelList: [],
            query: {
                Filter: [],
                PageNumber: 1,
                PageSize: process.env.VUE_APP_Default_Page_Size,
            },
            Filter: {},
            yearsList: Array(100).fill().map((_, id) => new Date().getFullYear() - id),
            FinancialStatementTypesList: []
        };
    },
    methods: {
        search: function () {
            var self = this;
            self.query.Filter = [];
            if (self.Filter.Year && self.Filter.Year.length > 0) {
                self.query.Filter.push({
                    FieldName: "Year",
                    Operation: "in",
                    value: self.Filter.Year.toString(),
                });
            }

            self.query.PageNumber = 1;
            self.modelList = [];
            self.isCheck = false;
            self.load();
        },

        load: function () {
            debugger;
            let self = this;
            if (!self.isLoading && !self.isCheck) {
                self.isLoading = true;
                self.axios
                    .post(process.env.VUE_APP_APIEndPoint + "AnnualReport/PublicGetAll", self.query)
                    .then(function (res) {
                        self.modelList = [...self.modelList, ...res.data.Data];
                        console.log(self.modelList);
                        self.query.PageNumber = res.data.PageNumber;
                        self.query.PageSize = res.data.PageSize;
                        self.total_rows = res.data.Total;
                        self.total_pages = Math.ceil(self.total_rows / res.data.PageSize);
                        if (self.total_pages > 0 && self.query.PageNumber <= 1) {
                            setTimeout(function () { }, 100);
                        }
                        self.isCheck = false;
                        if (self.total_pages <= self.query.PageNumber) self.isCheck = true;

                    })
                    .finally(() => self.isLoading = false);
            }

        },

        downloadFile: function (link) {
            var self = this;
            if (!link)
                return;

            const anchor = document.createElement('a');
            anchor.href = link;

            document.body.appendChild(anchor);

            anchor.click();

            document.body.removeChild(anchor);

        },
        Scroll: function () {
            let self = this;
            self.query.PageNumber++;
            self.load();
        },

        handleScroll: function (event) {
            let infiniteList = document.getElementById("infinite-list");
            if (infiniteList) {
                let listPosition = infiniteList.offsetTop + infiniteList.offsetHeight;

                if (window.scrollY >= listPosition - 600) {
                    this.Scroll();
                }
            }
        },
    },
    created() {
        window.addEventListener('scroll', this.handleScroll);
    },
    updated() {
        initGallerySlider();
    },
    mounted() {
        let self = this;
        self.load();
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    },
}
